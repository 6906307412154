import styled, { css } from "styled-components";
import { breakpoints, colors } from "../../styles/variables";
import { RiHospitalLine } from "react-icons/ri";
import { FaRegKeyboard } from "react-icons/fa";
import { GoHome } from "react-icons/go";
export const Container = styled.div`
  display: block;
  margin-bottom: 2rem;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 3rem;
  @media (min-width: ${breakpoints.lg}) {
    flex-direction: row;
  }
`;
export const Title = styled.h1`
  font-size: 1.3rem;
  padding-bottom: 2rem;
`;
export const Content = styled.p`
  padding-bottom: 1rem;
  line-height: 1.8rem;
  font-family: "Raleway", sans-serif;
`;
export const ContentBottom = styled.div`
  font-weight: 500;
  padding-bottom: 4rem;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 1.5rem 1rem;

  @media (min-width: ${breakpoints.md}) {
    margin: 1rem;
    padding: 1rem;
  }
  @media (min-width: ${breakpoints.lg}) {
    margin: 2rem 1rem;
  }
`;
export const TitleWrapper = styled.div`
  margin-left: 1rem;
`;
export const CardTitle = styled.p`
  font-weight: 600;
  margin: auto 0 auto 1rem;
`;

export const SubTitle = styled.p`
  margin: auto 0 auto 1rem;
  font-family: "Raleway", sans-serif;
`;

const IconStyle = css`
  font-size: 3rem;
  color: ${colors.dentCyan};
  font-weight: 200;

  @media (min-width: ${breakpoints.md}) {
    margin: 0;
  }
`;

export const RiHospitalLineIcon = styled(RiHospitalLine)`
  ${IconStyle}
`;

export const GoHomeIcon = styled(GoHome)`
  ${IconStyle}
`;

export const FaRegKeyboardIcon = styled(FaRegKeyboard)`
  ${IconStyle}
`;
export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const Column = styled.div`
  flex: 0 0 100%;
  padding: 0 0 3rem 0;

  @media (min-width: ${breakpoints.md}) {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 1.2rem 3rem 1.2rem;
  }

  @media (min-width: ${breakpoints.lg}) {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
`;
