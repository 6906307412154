import React, {useState, useRef, useEffect} from "react";
import Slider from "react-slick";
import useData from "./useData";
import { Wrapper, TopSliderWrapper, SliderWrapper, Left, Right } from "./styles";
import Slide from "./Slide";

const PracticePhotos = () => {
  const data = useData();
  const slideSettings = {
    slidesToShow: 5,
    swipeToSlide: true,
    focusOnSelect: true,
    slidesToScroll: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 15000,
    pauseOnHover: true,
    centerMode: true,
    centerPadding: "0px",
    cssEase: "ease-in-out",
    prevArrow: <Left />,
    nextArrow: <Right />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 980,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const slider1 = useRef(null);
  const slider2 = useRef(null);

  useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
  }, []);
  return (
    <Wrapper>
      <TopSliderWrapper>
        <Slider asNavFor={nav2} ref={slider1}>
          {data.map((item, index) => (
            <Slide key={`${index}key`} {...item} />
          ))}
        </Slider>
      </TopSliderWrapper>
      <SliderWrapper>
        <Slider 
          {...slideSettings}
          asNavFor={nav1}
          ref={slider2}
        >
          {data.map((item, index) => (
            <Slide key={`${index}key`} {...item} />
          ))}
        </Slider>
      </SliderWrapper>
    </Wrapper>
  );
};

export default PracticePhotos;
