import styled from "styled-components";
import { colors, breakpoints } from "../styles/variables";

const PageTitle = styled.h1`
  font-size: 1.2rem;
  color: ${colors.dark};
  max-width: ${breakpoints.xl};
  margin: 0 auto;
  padding: 0 1rem;
  @media (min-width: ${breakpoints.md}) {
    font-size: 1.8rem;
  }
  @media (min-width: ${breakpoints.xl}) {
    padding: 0;
    font-size: 2rem;
  }
`;

export default PageTitle;
