import React from "react";
import AboutImage from "./Images";
import {
  Container,
  Title,
  Content,
  Wrapper,
  Card,
  CardTitle,
  SubTitle,
  TitleWrapper,
  RiHospitalLineIcon,
  GoHomeIcon,
  FaRegKeyboardIcon,
  Row,
  Column,
} from "./styles";
import PageContainer from "../PageContainer";
import PracticePhotos from "../practice-photos";
import { Breadcrumb, BreadcrumbItem } from "../Breadcrumb";
import banner from "../../images/about/miro_photo_banner.jpg";
import BannerImage from "../BannerImg";
const About = () => {
  return (
    <>
      <Breadcrumb pageTitle="About Our Practice">
        <BreadcrumbItem Link="/about" Text="About Our Practice" />
      </Breadcrumb>

      <BannerImage imagesrc={banner}></BannerImage>

      <PageContainer>
        <Container>
          <Container>
            <Title>Gippsland prosthodontics</Title>
            <Content>
              Gippsland prosthodontics is a new clinic in the Gippsland area but
              not new in the specialist field. Dr. Miro, our principal dentist,
              brings vast experience from a number of years as a Prosthodontist
              to your service (please refer to below). We pride ourselves to be
              able to combine an excellent warm and welcoming environment with
              our modern state-of-the-art facilities.
            </Content>
            <Content>
              Our facilities have been carefully designed to make you feel at
              home. You can have peace of mind that we follow the highest
              standards of infection control and hygiene with continuous
              disinfection of water lines of equipment, this feature prevents
              backflow contamination of the dental unit water supply and acts
              against all water-borne contaminants. The suction system is
              automatically flushed and cleaned between one patient and the next
              eliminating the risk of cross-contamination. Our rigorous
              disinfection protocols are carefully followed while being
              environmentally friendly by using the power of Ozone disinfection
              of most common surfaces instead of harsh chemicals that can
              ultimately harm nature. The ozone is freshly created with our
              latest ozone filter by only using filtered water.
            </Content>
            <Content>
              Our team is very conscious of the carbon footprint; hence we are
              very proud to offer you the digital processes that allow us to be
              paper-free.
            </Content>
            <Content>
              Our comfort menu was carefully selected to provide the most
              comfortable experience. This can be quickly arranged upon arrival
              just ask our friendly staff or send us your request with your
              preferences. You just need to take a seat and relax!
            </Content>
          </Container>
          <Wrapper>
            <Card>
              <GoHomeIcon />
              <TitleWrapper>
                <CardTitle>Welcoming environment</CardTitle>
                <SubTitle>Feeling at home</SubTitle>
              </TitleWrapper>
            </Card>
            <Card>
              <RiHospitalLineIcon />
              <TitleWrapper>
                <CardTitle>Vast experience</CardTitle>
                <SubTitle>Highest standards of infection control</SubTitle>
              </TitleWrapper>
            </Card>
            <Card>
              <FaRegKeyboardIcon />
              <TitleWrapper>
                <CardTitle>Digital processes</CardTitle>
                <SubTitle>Paper free</SubTitle>
              </TitleWrapper>
            </Card>
          </Wrapper>
          <PracticePhotos />
        </Container>
      </PageContainer>
    </>
  );
};

export default About;
