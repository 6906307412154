import React from "react";
import Img from "gatsby-image";
import { SlideWrapper, SlideDrop } from "./styles";

const Slide = ({ image }) => (
  <SlideWrapper>
      <Img fluid={image} />
      <SlideDrop>
      </SlideDrop>
  </SlideWrapper>
);

export default Slide;
